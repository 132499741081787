<template>
  <b-container fluid="md" class="text-section mb-4 mt-5">
    <b-row>
      <b-col sm="auto"><h2>Was ich für Dich tun kann</h2></b-col>
    </b-row>
    <b-row>
      <b-col sm="auto"><h3>Beratung, Konzept, Management</h3></b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>
          Du hast eine Idee, ich helfe dir bei der Umsetzung
          und begleite Dein Projekt von Anfang bis zum Ende.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="auto"><h3>Entwicklung</h3></b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>
          PHP, HTML, CSS, JavaScript, Vue
          (CLI), jQuery, Symfony, Laravel,
          MySQL/NoSQL, npm, Composer, Git, SVN,
          Webpack, Docker (Compose) TDD, REST, Google API,
          Shopware 5/6, Livestreaming, PhpStorm, Ubuntu, Windows, etc.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'Skills'
}
</script>
