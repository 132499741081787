<template>
  <div id="app">
    <Title/>
    <Skills/>
    <Clients/>
    <Contact/>
  </div>
</template>

<script>

import Title from '~component/Title'
import Skills from '~component/Skills'
import Clients from '~component/Clients'
import Contact from '~component/Contact'

export default {
  name: 'App',
  components: {
    Title,
    Skills,
    Clients,
    Contact,
  }
}
</script>

<style>
#app {
  /*font-family: RobotoLight, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
