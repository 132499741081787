import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'

// import i18n from '~module/i18n/i18n'
// import { i18nModuleMixin } from '~module/i18n/i18n.module.extension'

import '~sass/app.scss'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
