<template>
  <div class="w-vh100 title-image">
    <div class="overlay-pattern w-100 h-100"></div>
    <b-container fluid="md" id="welcome" class="text-section">
      <b-row align-v="end">
        <b-col sm="auto">
          <h2>Tech/Web - Beratung, Entwicklung, Projekt Management</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="auto">
          <p>Carsten Müller</p>
          <!--          Undinestr. 6 | 12203 Berlin-->
          <!--          FON: +49 151 40222172 | Mail: cm@extrajetzt.de-->
        </b-col>
      </b-row>
    </b-container>
    <div class="divider-with-arrow text-center">
        <b-icon icon="chevron-down" font-scale="1.5" variant="primary"></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleImage'
}
</script>
