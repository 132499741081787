<template>
  <b-container fluid="md" class="text-section mb-4 mt-5">
    <b-row>
      <b-col sm="auto"><h2>Kunden</h2></b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-img fluid src="../assets/images/clients/ogilvy.png"></b-img>
      </b-col>
      <div class="d-block d-sm-none w-100"></div>
      <b-col>
        <b-img fluid src="../assets/images/clients/ard.png"></b-img>
      </b-col>
      <div class="d-block d-sm-none w-100"></div>
      <div class="d-sm-block d-md-none w-100"></div>
      <b-col>
        <b-img fluid src="../assets/images/clients/carlsen.png"></b-img>
      </b-col>
      <div class="d-block d-sm-none w-100"></div>
      <b-col>
        <b-img fluid src="../assets/images/clients/jvm.png"></b-img>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'Clients'
}
</script>
