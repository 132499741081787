import Vue from 'vue'

import {
  ButtonPlugin,
  LayoutPlugin,
  ImagePlugin,
  IconsPlugin
} from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(ButtonPlugin)
Vue.use(LayoutPlugin)
Vue.use(ImagePlugin)
Vue.use(IconsPlugin)
