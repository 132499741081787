<template>
  <div>

  <b-container fluid="md" class="text-section mb-4 mt-5">
    <b-row>
      <b-col sm="auto"><h3>Impressum/Kontakt</h3></b-col>
    </b-row>
    <b-row cols="1" cols-sm="2">
      <b-col>
        <p>
          Carsten Müller<br />
          Undinestr. 6<br />
          D-12203 Berlin<br />
        </p>
        <p>
          <b-button href="mailto:cm@extrajetzt.de" variant="light" class="text-decoration-none d-block my-1" title="Mail an Carsten">
            <b-icon icon="envelope"></b-icon> cm@extrajetzt.de
          </b-button>
          <b-button href="tel:+4915140222172" variant="light" class="text-decoration-none d-block my-1" title="Carsten anrufen">
            <b-icon icon="telephone"></b-icon> +49 151 40222172
          </b-button>
        </p>
      </b-col>
    </b-row>
  </b-container>
  <b-container fluid="md" class="text-section mb-4 mt-5">
    <b-row>
      <b-col sm="auto"><h3>Datenschutz</h3></b-col>
    </b-row>
    <b-row cols="1" cols-sm="2">
      <b-col>
        <h4>Geltungsbereich</h4>
        <p>Diese Datenschutzerklärung soll die Nutzer dieser Website gemäß europäschischer Datenschutzgrundverordnung (DSGVO) über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber</p>
        <p>
          Carsten Müller<br />
          Undinestr. 6<br />
          D-12203 Berlin<br />
          Webseite: https://cm.extrajetzt.de
        </p>
        <p>informieren. Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Bedenken Sie, dass die Datenübertragung im Internet grundsätzlich mit Sicherheitslücken bedacht sein kann. Ein vollumfänglicher Schutz vor dem Zugriff durch Fremde ist nicht realisierbar.</p>
      </b-col>
      <b-col>
        <h4>Zugriffsdaten</h4>
        <p>Der Websitebetreiber bzw. Seitenprovider erhebt Daten über Zugriffe auf die Seite und speichert diese als 'Server-Logfiles' ab. Folgende Daten werden so protokolliert:</p>
        <ul><li>Besuchte Website</li><li>Uhrzeit zum Zeitpunkt des Zugriffes</li><li>Menge der gesendeten Daten in Byte</li><li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li><li>Verwendeter Browser</li><li>Verwendetes Betriebssystem</li><li>Verwendete IP-Adresse</li></ul>
        <p>Die erhobenen Daten dienen lediglich statistischen Auswertungen und zur Verbesserung der Website. Der Websitebetreiber behält sich allerdings vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.</p>
      </b-col>
    </b-row>
  </b-container>
  </div>
</template>
<script>
export default {
  name: 'Contact'
}
</script>
